import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LayoutIndex",
    redirect: "/index",
    component: () => import("@/views/layout/index"),
    children: [
      {
        path: "/404",
        component: () => import("@/views/error/pageNotFound"),
        hidden: true,
      },
      {
        path: "/index",
        name: "Index",
        component: () => import("@/views/index"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/index/login"),
      },
      {
        path: "/detail/:id",
        name: "NewsDetail",
        component: () => import("@/views/index/newsDetail"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  //mode: "history",
});
router.beforeEach((to, from, next) => {
  if (to.meta.isAdmin) {
    if (store.state.user.user.type == 99) {
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});

export default router;
