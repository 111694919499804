import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/common.css";
import "amfe-flexible";
import "amfe-flexible/index.js";

Vue.use(Vant);
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);

import "@/style/index.scss";
import "@/style/iconfont.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
